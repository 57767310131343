
import { Options, Vue } from 'vue-class-component';
import { AdminClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Dashboard extends Vue {

    model: OM.DashboardVM = new OM.DashboardVM();

    created() {
        this.init();
    }
    
    init() {
        AdminClient.dashboard()
        .then(x => {
            this.model = x;
        })
    }

}
